.helpbutton {
  padding: 0.5rem;
  width: 80px;
  height: 80px;
  display: flex;
  background: url("help.webp");
  background-repeat: no-repeat;
  background-size: contain, cover;
  cursor: pointer;
}

.helpbutton:hover {
  width: 90px;
  height: 90px;
  animation:rung-lac 1s ease infinite
}

.helpbutton:active {
  width: 80px;
  height: 80px;
}

@keyframes rung-lac {
    0% { -webkit-transform:rotate(0) scale(1) skew(1deg) }
    10% {-webkit-transform:rotate(-25deg) scale(1) skew(1deg)}
    20%{-webkit-transform:rotate(25deg) scale(1) skew(1deg)}
    30%{-webkit-transform:rotate(-25deg) scale(1) skew(1deg)}
    40%{-webkit-transform:rotate(25deg) scale(1) skew(1deg)}
    50%{-webkit-transform:rotate(0) scale(1) skew(1deg)}
    100%{-webkit-transform:rotate(0) scale(1) skew(1deg)}
}



