.treasure_chest {
  padding: 0.5rem;
  width: 56px;
  height: 56px;
  display: flex;
  background: url("treasure_chest.png");
  background-repeat: no-repeat;
  background-size: contain, cover;
  cursor: pointer;
}

.treasure_chest_mobile {
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  background: url("treasure_chest.png");
  background-repeat: no-repeat;
  background-size: contain, cover;
  cursor: pointer;
}

.treasure_chest:hover {
  animation:rung-lac 1s ease infinite
}

.help_pool:hover {
  cursor: pointer;
}

.help_pool:hover {
  animation:rung-lac 1s ease infinite;
  color: #5BE584;
}

@keyframes rung-lac {
    0% { -webkit-transform:rotate(0) scale(1) skew(1deg) }
    10% {-webkit-transform:rotate(-35deg) scale(1) skew(1deg)}
    20%{-webkit-transform:rotate(35deg) scale(1) skew(1deg)}
    30%{-webkit-transform:rotate(-35deg) scale(1) skew(1deg)}
    40%{-webkit-transform:rotate(35deg) scale(1) skew(1deg)}
    50%{-webkit-transform:rotate(0) scale(1) skew(1deg)}
    100%{-webkit-transform:rotate(0) scale(1) skew(1deg)}
}



