.ino1 {
    z-index: -1;
    position:relative;
    background-image: url("sold-out.png");
    background-repeat: no-repeat;
    background-size: 5%;
    background-position: center;
    background-position-x: 3%;
    background-position-y: 2%;
}
